import React, { useState, useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { Context } from "~/context/Context"
import Seo from "~/components/Seo"
import Preloader from "~/components/Preloader"
import VideoSlider from "~/components/VideoSlider"
import Scroller from "~/components/Scroller"
import ProjectShowcase from "~/components/ProjectShowcase"
import CardSlider from "~/components/CardSlider"
import Footer from "~/components/Footer"

function IndexPage({ data }) {
  const {
    data: { hasPreloaderBeenShown },
    set,
  } = useContext(Context)
  let { heroProjects, scroller1, scroller2, projectGrid, cardSlider, footer } =
    data.datoCmsHomepage
  const allVideos = data.allVimeo.nodes
  const [isPreloaderInit, setPreloaderInit] = useState(false)
  const [isPreloaderShown, setPreloaderShown] = useState(false)
  scroller1 = scroller1[0]
  scroller2 = scroller2[0]
  cardSlider = cardSlider[0]
  footer = footer[0]

  mergeVideoData(heroProjects)
  mergeVideoData(projectGrid)

  useEffect(() => {
    if (!hasPreloaderBeenShown) {
      setPreloaderInit(true)
      setTimeout(() => {
        setPreloaderShown(true)
      }, 1)
    }
  }, [])

  function getVideo(project, type) {
    return allVideos.find((item) => item.id === project[type].providerUid)
  }

  function mergeVideoData(projects) {
    projects.forEach((project) => {
      const shortVideo = getVideo(project, "shortVideo")
      const fullVideo = getVideo(project, "fullVideo")
      project.shortVideo.poster = shortVideo?.pictures?.base_link
      project.shortVideo.files = shortVideo?.srcset
      project.fullVideo.poster = fullVideo?.pictures?.base_link
      project.fullVideo.files = fullVideo?.srcset
    })
  }

  function scroller({ scrollingText, buttonText, buttonUrl }) {
    return (
      <Scroller
        mainText={scrollingText}
        buttonText={buttonText}
        buttonUrl={buttonUrl}
        onButtonClick={() => {
          if (buttonUrl) {
            window.open(buttonUrl)
          } else {
            set({ isAboutPanelVisible: true })
          }
        }}
      />
    )
  }

  return (
    <>
      <Seo title="Studio SDK" />
      <Preloader />
      <div
        className={`homepage-content ${
          isPreloaderInit ? "-preloader-init" : ""
        } ${isPreloaderShown ? "-preloader-shown" : ""}`}
      >
        <VideoSlider projects={heroProjects} />
        {scroller(scroller1)}
        <ProjectShowcase projects={projectGrid} />
        <CardSlider
          title={cardSlider.title}
          cards={cardSlider.services}
          onCardClick={() => set({ isAboutPanelVisible: true })}
        />
        {scroller(scroller2)}
        <Footer
          headline={footer.headline}
          team={footer.team}
          offices={footer.offices}
          navigationBar={footer.navigationBar[0]}
        />
      </div>
    </>
  )
}

export const query = graphql`
  query {
    datoCmsHomepage {
      heroProjects {
        client
        title
        shortVideo {
          providerUid
        }
        fullVideo {
          providerUid
        }
      }
      scroller1 {
        scrollingText
        buttonText
        buttonUrl
      }
      scroller2 {
        scrollingText
        buttonText
        buttonUrl
      }
      projectGrid {
        client
        title
        thumbnailSize: homepageThumbnailSize
        shortVideo {
          providerUid
        }
        fullVideo {
          providerUid
        }
      }
      cardSlider {
        title
        services {
          title
          tagline
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              aspectRatio: 0.70852018
              breakpoints: [200, 300, 400, 500, 600, 700, 800, 900, 1000]
              sizes: "(min-width: 768px) 29vw, 80vw"
              imgixParams: { q: 60, ar: "0.70852018", fit: "crop" }
            )
          }
        }
      }
      footer {
        headline
        team {
          name
          title
          email
        }
        offices {
          city
          email
          timeZone
        }
        navigationBar {
          copyrightText
          links {
            ... on DatoCmsLink {
              text
              url
            }
            ... on DatoCmsEmailLink {
              email
              text
            }
          }
        }
      }
    }
    allVimeo {
      nodes {
        id
        pictures {
          base_link
        }
        srcset {
          rendition
          link
        }
      }
    }
  }
`

export default IndexPage
